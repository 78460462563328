import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls"
import Table, { dateFormatter, buttonsStyle, booleanFormatter } from '../../../components/tables/table'
import { getOrders, getOrderById } from "../../../../api/orders"
import { getMarkets } from "../../../../api/markets"
import { getCategories } from "../../../../api/categories"
import { alertError } from "../../../../utils/logger"
import { Button, Tooltip } from "@material-ui/core"
import DatePicker from 'react-datepicker'
import DownloadIcon from '@material-ui/icons/GetApp'
import xlsx from 'json-as-xlsx'

function getData(orders, markets) {
  let data = []
  for (let i = 0; i < orders.length; ++i) {
    const elem = {}
    console.log(orders[i]);
    elem.email = orders[i].email
    elem.market = orders[i].market ? markets.find(cat => cat._id === orders[i].market)?.name : '---'
    elem.products = orders[i].products.map(item => item.name).join(', ')
    elem.createdAt = orders[i].createdAt
    elem.cancelled = orders[i].cancelled
    elem.id = orders[i]._id
    data = data.concat(elem)
  }
  return data
}

/*function getTotalPriceOrder(products) {
  let sum = 0.0
  products.forEach(item => sum += parseFloat(item.priceTotal))
  return sum
}

function getProductsInfo(products) {
  return products.map(item => ('ID: ' + item.id + " - NAME: " + item.name + " - QUANTITY: " + item.qty + " - PRICE (€): "+  item.priceTotal)).join("\n")
}*/

/*function exportToCsv(filename, rows) {
  let csvFile = ''
  csvFile += `${[
    'ORDER ID',
    'USER EMAIL',
    'DATE',
    'TOTAL PRICE (€)',
    'PRODUCTS',
  ].join(',')}\r\n`

  rows.forEach((row) => {
    const properValues = [row._id, row.email, row.createdAt.toLocaleString(), getTotalPriceOrder(row.products), getProductsInfo(row.products)]
    return (csvFile += `${properValues.join(',')}\r\n`)
  });

  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement("a")
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob)
      link.setAttribute("href", url)
      link.setAttribute("download", filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}*/

function exportExcel(fileName, rows, markets, categories) {
    const content = []
    rows.forEach((row) => {
      row.products.forEach((rowprod) => {
        const elem = {
          id: row._id,
          email: row.email,
          market: row.market ? markets.find(cat => cat._id === row.market)?.name : '---',
          date: row.createdAt.toLocaleString(),
          name: rowprod.name,
          qty: rowprod.qty,
          idprod: rowprod.id,
          price: rowprod.priceTotal,
          code: rowprod.code,
          category: rowprod.category ? categories.find(cat => cat._id === rowprod.category)?.name : '---',
          cancelled: row.cancelled ? 'Cancelled' : ''
        }
        content.push(elem)
      })
    });

    let xlsxFile = [
      {
        sheet: 'Orders',
        columns: [
          { label: 'ORDER ID', value: 'id' },
          { label: 'STATUS', value: 'cancelled' },
          { label: 'USER EMAIL', value: 'email' },
          { label: 'DATE', value: 'date' },
          { label: 'MARKET', value: 'market' },
          { label: 'TYPE', value: 'category' },
          { label: 'CODE', value: 'code' },
          { label: 'NAME', value: 'name' },
          { label: 'QUANTITY', value: 'qty' },
          { label: 'PRICE', value: 'price' }
        ],
        content
      }
    ]
    const settings = {
      fileName,
      extraLength: 10
    }
    xlsx(xlsxFile, settings)
}


export default function OrdersPage() {
  const [data, setData] = useState([])
  const [markets, setMarkets] = useState([])
  const [categories, setCategories] = useState([])
  const [excelData, setExcelData] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')

  function buttonFormatter(cell) {
    return (<>
      <Tooltip title="Export excel">
        <Button
          style={buttonsStyle} size="small"
          onClick={() =>
            getOrderById(cell).then((res) => {
              if (res.status === 200) {
                exportExcel('Licor43Order#' + res.data._id, [res.data], markets, categories)
              }
            }).catch(error => {
              alertError({ error: error, customMessage: 'Could not get order.' })
            })
          }>
          <DownloadIcon/>
        </Button>
      </Tooltip>
    </>)
  }

  const columns = [
    { dataField: 'email', text: 'User email' },
    { dataField: 'cancelled', text: 'Cancelled?', align: 'center', headerAlign: 'center', formatter: booleanFormatter },
    { dataField: 'products', text: 'Products' },
    { dataField: 'market', text: 'Market' },
    { dataField: 'createdAt', text: 'Created at', formatter: dateFormatter },
    { dataField: 'id', text: '', formatter: buttonFormatter },
  ]

  useEffect(() => {
    getOrders().then((res) => {
      if (res.status === 200) {
        setData(res.data)
        setRefresh(false)
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get orders.' })
    })
    getMarkets().then((resp) => {
      if (resp.status === 200) {
        setMarkets(resp.data)
        setRefresh(false)
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get markets.' })
    })
    getCategories().then((resp) => {
      if (resp.status === 200) {
        setCategories(resp.data)
        setRefresh(false)
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get markets.' })
    })
  }, [refresh])

  useEffect(() => {
    if (!data.length) return
    setExcelData(data.filter(item => {
      const newDate = new Date(item.createdAt)
      const filter = true
      if (fromDate && fromDate !== '') return filter && newDate >= fromDate
      if (toDate && toDate !== '') return filter && newDate <= toDate
      return item
    }))
  }, [data, fromDate, toDate])

  return (
    <>
      <Card>
        <CardHeader title='Orders list'>
          <CardHeaderToolbar>
            <div className='mr-4'>
              <DatePicker dateFormat="dd/MM/yyyy" placeholderText="From:" selected={fromDate} isClearable onChange={(date) => setFromDate(date)}/>
            </div>
            <div className='mr-4'>
              <DatePicker dateFormat="dd/MM/yyyy" placeholderText="To:" selected={toDate} isClearable onChange={(date) => setToDate(date)}/>
            </div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => exportExcel('Licor43Orders', excelData, markets, categories)}
            >
              Export excel
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table
            data={getData(excelData, markets)}
            columns={columns}
          />
        </CardBody>
      </Card>
    </>
  );
}
